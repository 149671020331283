// hooks
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

// types
import { AccountDocument, FollowStatsType } from '../../../graphql/generated'

// utils
import { DateTime } from 'luxon'
import { getExpiryDate } from '../../../utils/date-utils'

type AccountDetails = {
  email: string
  expiryDate: DateTime
  followStats: FollowStatsType
  orgName: string
  tier: string | null
}

const useAccountPopover = () => {
  const { data } = useQuery(AccountDocument)

  const accountDetails = useMemo<AccountDetails | null>(() => {
    if (data) {
      const { organization, user } = data
      const { followStats, name, tier } = organization
      const { email, profile } = user

      const expiryDate = getExpiryDate(organization.expiryDate, profile?.expiryDate)

      return {
        email,
        expiryDate,
        followStats,
        orgName: name,
        tier: tier?.name || null
      }
    }

    return null
  }, [data])

  return { accountDetails }
}

export default useAccountPopover
