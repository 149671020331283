import { FC, MouseEvent, ReactNode, useCallback, useState } from 'react'

// components
import AccountPopover from './AccountPopover/AccountPopover'
import BlockingNotificationModal from '../../features/notification/blocking-notification-modal/blocking-notification-modal'
import ConnectionsModal from './ConnectionsModal'
import InfoPopover from './InfoPopover'
import LockableLink from './LockableLink'
import LogoutModal from './LogoutModal'
import NotificationButton from '../../features/notification/notification-button'

// graphql
import { ColliersDocument, ColliersQuery } from '../../graphql/generated'

// hooks
import { useAuth } from '../../contexts/auth-context'
import { usePermissions } from '../../contexts/permissions/permissions-context'
import { useQuery } from '@apollo/client'
import { useTutorial } from '../../features/tutorial/tutorial-context/tutorial-context'

// router
import { Link } from 'react-router-dom'

// ui
import { Card, Divider, Grid, IconButton, Stack, styled, Tooltip, useTheme } from '@mui/material'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'

const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100vh'
}))

const Content = styled('div')(({ theme }) => ({
  height: `calc(100vh - ${theme.spacing(6)})`
}))

const Header = styled(Card)(({ theme }) => ({
  background: theme.palette.primary.main,
  height: theme.spacing(6),
  position: 'relative',
  zIndex: 10
}))

Header.defaultProps = { elevation: 3, square: true }

const HeaderGridContainer = styled(Grid)(({ theme }) => ({
  height: '100%',
  padding: `0px ${theme.spacing()}`
}))
HeaderGridContainer.defaultProps = { container: true }

const Logo = styled('img')(({ theme }) => ({
  height: theme.spacing(5),
  aspectRatio: '256/146'
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.primary.contrastText,
  borderRightWidth: 2
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}))

const StyledStack = styled(Stack)`
  align-items: center;
  height: 100%;
`
StyledStack.defaultProps = { spacing: 3 }

interface ILayoutProps {
  children: ReactNode
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const [accountAnchorEl, setAccountAnchorEl] = useState<Element | null>(null)
  const [infoAnchorEl, setInfoAnchorEl] = useState<Element | null>(null)
  const [showConnections, setShowConnections] = useState<boolean>(false)
  const [showLogout, setShowLogout] = useState<boolean>(false)

  const { token } = useAuth()

  const {
    permissions: { admin, areaAnalysis, csm, downloader, explore, groupView, locationAffinity }
  } = usePermissions()

  const { data: colliersData } = useQuery<ColliersQuery>(ColliersDocument, {
    fetchPolicy: 'cache-only'
  })

  const handleAccountOpen = (event: MouseEvent<HTMLElement>) => {
    setAccountAnchorEl(event.currentTarget)
  }

  const handleAccountClose = useCallback(() => {
    setAccountAnchorEl(null)
  }, [])

  const handleInfoOpen = (event: MouseEvent<HTMLElement>) => {
    setInfoAnchorEl(event.currentTarget)
  }

  const handleInfoClose = () => {
    setInfoAnchorEl(null)
  }

  const { teaching, toggleTeaching } = useTutorial()
  const { palette } = useTheme()

  return (
    <Container>
      <Header>
        <HeaderGridContainer>
          <Grid item xs={6}>
            <StyledStack direction='row'>
              {colliersData?.organization.colliers && <Logo src='/colliers.png' />}
              <LockableLink locked={false} path='/' title='myTerain' />
              <LockableLink locked={!admin && !explore} path='/explore' title='Explore' />
              <LockableLink locked={!admin && !downloader} path='/downloader' title='Downloader' />
              <LockableLink locked={!admin && !groupView} path='/group' title='Group' />
              
              <LockableLink
                locked={!admin && !areaAnalysis}
                path='/analysis-area'
                title='Area Analysis'
              />
              
              {(admin || locationAffinity) && (
                <LockableLink locked={false} path='/location-affinity' title='Location Affinity' />
              )}

              {(admin || csm) && <LockableLink locked={false} path='/csm' title='CSM' />}
            </StyledStack>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={2}>
            <StyledStack direction='row-reverse'>
              {!!token && (
                <>
                  <Tooltip title='Logout'>
                    <StyledIconButton onClick={() => setShowLogout(true)}>
                      <LogoutRoundedIcon />
                    </StyledIconButton>
                  </Tooltip>
                  <StyledIconButton onMouseEnter={handleAccountOpen}>
                    <PersonOutlineOutlinedIcon />
                  </StyledIconButton>
                </>
              )}
              <Tooltip title='Help Center'>
                <Link to='/help'>
                  <StyledIconButton>
                    <HelpOutlineOutlinedIcon />
                  </StyledIconButton>
                </Link>
              </Tooltip>
              <NotificationButton />
              <Tooltip title='Help Mode'>
                <StyledIconButton
                  sx={{ background: teaching ? palette.primary.dark : undefined }}
                  onClick={toggleTeaching}
                >
                  <QuestionMarkIcon />
                </StyledIconButton>
              </Tooltip>
              <StyledIconButton onMouseEnter={handleInfoOpen}>
                <InfoOutlinedIcon />
              </StyledIconButton>
              {admin && (
                <>
                  <StyledDivider flexItem orientation='vertical' />
                  <Tooltip title='Admin'>
                    <StyledIconButton
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_BASE_URL}/admin/login/?next=/api/admin/`,
                          '_blank'
                        )
                      }
                    >
                      <SettingsOutlinedIcon />
                    </StyledIconButton>
                  </Tooltip>
                  <Tooltip title='Connections'>
                    <StyledIconButton onClick={() => setShowConnections(true)}>
                      <PowerOutlinedIcon />
                    </StyledIconButton>
                  </Tooltip>
                </>
              )}
            </StyledStack>
          </Grid>
        </HeaderGridContainer>
      </Header>
      <Content>{children}</Content>
      <LogoutModal onClose={() => setShowLogout(false)} open={showLogout} />
      <AccountPopover anchorEl={accountAnchorEl} onClose={handleAccountClose} />
      <InfoPopover anchorEl={infoAnchorEl} onClose={handleInfoClose} />
      {admin && (
        <ConnectionsModal onClose={() => setShowConnections(false)} open={showConnections} />
      )}
      <BlockingNotificationModal />
    </Container>
  )
}

export default Layout
