import { FC } from 'react'

// components
import VHAligner from '../common/VHAligner'

// hooks
import { useAuth } from '../../contexts/auth-context'

// ui
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

const Content = styled(Paper)(({ theme }) => ({
  left: '60vh',
  padding: theme.spacing(),
  position: 'absolute',
  right: '60vh',
  top: '30vh'
}))

interface IProps {
  onClose: () => void
  open: boolean
}

const LogoutModal: FC<IProps> = ({ onClose, open }) => {
  const { logout } = useAuth()

  const handleLogout = () => {
    if (logout) {
      logout()
    }

    onClose()
  }

  return (
    <Modal onClose={onClose} open={open}>
      <Content>
        <Stack spacing={1}>
          <VHAligner>
            <Typography variant='h6'>Are you sure you would like to logout?</Typography>
          </VHAligner>
          <VHAligner>
            <Stack direction='row' spacing={1}>
              <Button data-testid='confirmLogoutBtn' onClick={handleLogout} variant='contained'>
                Logout
              </Button>
              <Button onClick={onClose} variant='outlined'>
                Cancel
              </Button>
            </Stack>
          </VHAligner>
        </Stack>
      </Content>
    </Modal>
  )
}

export default LogoutModal
