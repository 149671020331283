// components
import { Link } from 'react-router-dom'

// types
import { FC } from 'react'

// ui
import { Button, styled } from '@mui/material'

const StyledButton = styled(Button)`
  padding: 0px;
  text-transform: none;
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

type ButtonLinkProps = {
  onClick?: () => void
  text: string
  to: string
}

const ButtonLink: FC<ButtonLinkProps> = ({ onClick, text, to }) => (
  <StyledButton onClick={onClick}>
    <StyledLink to={to}>
      {text}
    </StyledLink>
  </StyledButton>
)

export default ButtonLink
