import { FC } from 'react'

// components
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import Layout from './components/Layout/Layout'
import MainRoutes from './components/MainRoutes'
import NotificationProvider from './features/notification/notification-provider/notification-provider'
import { PermissionsProvider } from './contexts/permissions/permissions-context'
import ThemeSwitcher from './components/ThemeSwitcher/ThemeSwitcher'
import TutorialProvider from './features/tutorial/tutorial-context/tutorial-provider'

// graphql
import { ApolloProvider } from '@apollo/client'
import { createApolloClient } from './apollo'

// hooks
import { useAuth } from './contexts/auth-context'

// routes
import { BrowserRouter } from 'react-router-dom'

const App: FC = () => {
  const { logout, token } = useAuth()

  return (
    <ApolloProvider client={createApolloClient(logout, token)}>
      <BrowserRouter>
        <ThemeSwitcher>
          <PermissionsProvider>
            <NotificationProvider>
              <TutorialProvider>
                <Layout>
                  <ErrorBoundary token={token}>
                    <MainRoutes />
                  </ErrorBoundary>
                </Layout>
              </TutorialProvider>
            </NotificationProvider>
          </PermissionsProvider>
        </ThemeSwitcher>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
