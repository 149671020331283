// contexts
import { createContext, useContext } from 'react'

// types
import { Notification } from './notification-types'

const NotificationContext = createContext<
  { loading: boolean; notifications: Notification[] } | undefined
>(undefined)

const useNotifications = () => {
  const context = useContext(NotificationContext)

  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationContextProvider')
  }

  return context
}

export { NotificationContext, useNotifications }
