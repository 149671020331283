// hooks
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'

// types
import { NotificationsDocument } from '../../../graphql/generated'
import { Notification } from '../notification-types'

// utils
import { transformNotificationFragment } from '../notification-utils'

const useNotificationProvider = () => {
  const { data, loading } = useQuery(NotificationsDocument, { pollInterval: 30000 })

  const notifications = useMemo<Notification[]>(() => {
    if (!data) return []

    const unsorted = data.organization.notifications.map(notification =>
      transformNotificationFragment(notification)
    )

    return [...unsorted].sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
  }, [data])

  return { loading, notifications }
}

export default useNotificationProvider
