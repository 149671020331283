// hooks
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

// types
import { BlockingNotificationsDocument, SeeNotificationDocument } from '../../../graphql/generated'
import { Notification } from '../notification-types'

// utils
import { transformNotificationFragment } from '../notification-utils'

const useBlockingNotificationModal = () => {
  const { data: blockingNotificationsData, loading: blockingNotificationsLoading } = useQuery(
    BlockingNotificationsDocument
  )

  const sortedBlockingNotifications = useMemo<Notification[]>(() => {
    if (!blockingNotificationsData) return []

    const unsorted = blockingNotificationsData.user.blockingNotifications.reduce<Notification[]>(
      (unsortedAcc, blockingNotification) => {
        if (blockingNotification.seenByCurrentUser) return unsortedAcc
        unsortedAcc.push(transformNotificationFragment(blockingNotification))
        return unsortedAcc
      },
      []
    )

    return [...unsorted].sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis())
  }, [blockingNotificationsData])

  const [openedNotification, setOpenedNotification] = useState<Notification | null>(null)
  const [seeNotification, { loading: seeing }] = useMutation(SeeNotificationDocument)

  useEffect(() => {
    if (blockingNotificationsLoading || openedNotification || seeing) return
    const latestBlocker = sortedBlockingNotifications[0]
    if (!latestBlocker) return
    setOpenedNotification({ ...latestBlocker })
    seeNotification({ variables: { notificationId: latestBlocker.id } })
  }, [
    blockingNotificationsLoading,
    openedNotification,
    seeing,
    seeNotification,
    sortedBlockingNotifications
  ])

  const handleClose = () => {
    setOpenedNotification(null)
  }

  return { handleClose, openedNotification, seeing }
}

export default useBlockingNotificationModal
