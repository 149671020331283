import { FC } from 'react'

// components
import VHAligner from '../../components/common/VHAligner'

// hooks
import { useAuth } from '../auth-context'

// ui
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

// utils
import { DateTime } from 'luxon'

const Logo = styled('img')`
  height: 10vh;
  margin: 100px 0px 10px 0px;
  aspect-ratio: 3/1;
`

const StyledVHAligner = styled(VHAligner)(({ theme }) => ({
  paddingTop: theme.spacing()
}))

interface IProps {
  expiryDate: DateTime
}

const Expired: FC<IProps> = ({ expiryDate }) => {
  const { logout } = useAuth()

  return (
    <Grid container>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <VHAligner>
          <Logo src='/terain.png' />
        </VHAligner>
        <Stack spacing={1}>
          <Typography variant='h6'>{`Your account access expired on ${expiryDate.toFormat(
            'd MMMM yyyy'
          )}`}</Typography>
          <Typography>
            We hope you&apos;ve found Terain useful. Please email{' '}
            <a href='mailto:customersuccess@fetchanalytics.ai'>customersuccess@fetchanalytics.ai</a>{' '}
            to extend your access.
          </Typography>
          <Typography>
            If you think your access has been extended, try logging out and back in again.
          </Typography>
        </Stack>
        <StyledVHAligner>
          <Button onClick={() => !!logout && logout()} variant='contained'>
            Logout
          </Button>
        </StyledVHAligner>
      </Grid>
    </Grid>
  )
}

export default Expired
