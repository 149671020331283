import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react'

// components
import Expired from './Expired'
import UpgradeModal from './UpgradeModal'
import UpgradeFollowModal from './UpgradeFollowModal'

// hooks
import { useAuth } from '../auth-context'

// graphql
import {
  OrganizationAccountStatus,
  PermissionsDocument,
  PermissionsQuery,
  PermissionsQueryVariables
} from '../../graphql/generated'
import { useQuery } from '@apollo/client'

// types
import { UpgradeFollowModalProps } from './types'

// utils
import { getExpiryDate } from '../../utils/date-utils'
import { DateTime } from 'luxon'

export type Permissions = {
  admin: boolean
  areaAnalysis: boolean
  compare: boolean
  consumers: boolean
  csm: boolean
  demographicFollows: number
  demographicIds: string[]
  downloader: boolean
  explore: boolean
  export: boolean
  follows: number
  groupView: boolean
  locationAffinity: boolean
  locations: boolean
  market: boolean
  notifier: boolean
  visitors: boolean
  visitorsDetails: boolean
  workers: boolean
}

const DEFAULT_PERMISSIONS: Permissions = {
  admin: false,
  areaAnalysis: false,
  compare: false,
  consumers: false,
  csm: false,
  demographicFollows: 0,
  demographicIds: [],
  downloader: false,
  explore: false,
  export: false,
  follows: 0,
  groupView: false,
  locationAffinity: false,
  locations: false,
  market: false,
  notifier: false,
  visitors: false,
  visitorsDetails: false,
  workers: false
}

type PermissionsContext = {
  loading: boolean
  permissions: Permissions
  upgrade: (feature: string) => void
  upgradeFollow: (props: Omit<UpgradeFollowModalProps, 'onClose'>) => void
}

interface IProps {
  children: ReactNode
}

const PermissionsContext = createContext<PermissionsContext | undefined>(undefined)

const PermissionsProvider: FC<IProps> = ({ children }) => {
  const [expired, setExpired] = useState<boolean>(false)
  const [expiryDate, setExpiryDate] = useState<DateTime | null>(null)
  const [upgradeFeature, setUpgradeFeature] = useState<string | null>(null)
  const [upgradeFollowProps, setUpgradeFollowProps] = useState<Omit<
    UpgradeFollowModalProps,
    'onClose'
  > | null>(null)

  const upgrade = useCallback((feature: string) => {
    setUpgradeFeature(feature)
  }, [])

  const upgradeFollow = useCallback((props: Omit<UpgradeFollowModalProps, 'onClose'>) => {
    setUpgradeFollowProps(props)
  }, [])

  const [permissions, setPermissions] = useState<Permissions>({ ...DEFAULT_PERMISSIONS })
  const { token } = useAuth()
  const { data, loading } = useQuery(PermissionsDocument)

  useEffect(() => {
    if (data) {
      const { organization, user } = data
      const { accountStatus, demographics, expiryDate: orgExpiryDate } = organization
      const { isActive, isCsmUser, isNotifier, isStaff, isSuperuser, profile } = user

      const isAdmin = isStaff || isSuperuser
      const newExpiryDate = getExpiryDate(orgExpiryDate, profile?.expiryDate)

      const isExpired =
        !isAdmin &&
        (accountStatus === OrganizationAccountStatus.Exuser ||
          !isActive ||
          DateTime.now() > newExpiryDate)

      setExpired(isExpired)
      setExpiryDate(newExpiryDate)

      if (!isExpired && !!profile?.permissions) {
        const perms = profile.permissions

        setPermissions({
          admin: isAdmin,
          areaAnalysis: perms.areaAnalysis,
          compare: perms.compare || false,
          consumers: perms.consumers || false,
          csm: isCsmUser,
          demographicFollows: perms.demographicFollows,
          demographicIds: demographics,
          downloader: perms.downloader || false,
          explore: perms.search || false,
          export: perms.export || false,
          follows: perms.follows || 0,
          groupView: perms.groupView,
          locationAffinity: perms.locationAffinity,
          locations: perms.locations || false,
          market: perms.market || false,
          notifier: isNotifier,
          visitors: perms.visitors || false,
          visitorsDetails: perms.visitorsDetails || false,
          workers: perms.workers || false
        })
      }
    } else {
      setPermissions({ ...DEFAULT_PERMISSIONS })
    }
  }, [data])

  useEffect(() => {
    // Revert permissions on logout
    if (!token) {
      setExpired(false)
      setExpiryDate(null)
      setUpgradeFeature(null)
      setPermissions({ ...DEFAULT_PERMISSIONS })
    }
  }, [token, upgrade, upgradeFollow])

  return expired && !!expiryDate ? (
    <Expired expiryDate={expiryDate} />
  ) : (
    <>
      <PermissionsContext.Provider value={{ loading, permissions, upgrade, upgradeFollow }}>
        {children}
      </PermissionsContext.Provider>
      <UpgradeModal
        feature={upgradeFeature || 'Feature'}
        open={!!upgradeFeature}
        onClose={() => setUpgradeFeature(null)}
      />
      {upgradeFollowProps && (
        <UpgradeFollowModal {...upgradeFollowProps} onClose={() => setUpgradeFollowProps(null)} />
      )}
    </>
  )
}

function usePermissions() {
  const context = useContext(PermissionsContext)

  if (context === undefined) {
    throw new Error('usePermission must be used within a PermissionsProvider')
  }

  return context
}

export { PermissionsProvider, usePermissions }
