// hooks
import { useCallback, useState } from 'react'

// types
import { TutorialMessage } from '../tutorial-types'

const useTutorialProvider = () => {
  const [message, setMessage] = useState<TutorialMessage | null>(null)
  const [teaching, setTeaching] = useState<boolean>(false)

  const toggleTeaching = useCallback(() => {
    setTeaching(oldTeaching => !oldTeaching)
  }, [])

  return { message, setMessage, teaching, toggleTeaching }
}

export default useTutorialProvider
