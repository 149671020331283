import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme'
import { linearGradientDef, Theme as NivoTheme } from '@nivo/core'

// Nivo charts do not allow string values for padding
// font sizes and so on. So we provide our theme with
// number based values along side the default string ones.
declare module '@mui/material/styles' {
  interface Theme {
    numSpacing: (value?: number) => number
  }
  interface ThemeOptions {
    numSpacing: (value?: number) => number
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    variety: string[]
  }
  interface PaletteOptions {
    variety: string[]
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    tiny: true
  }
}

declare module '@mui/material/Table' {
  interface TablePropsSizeOverrides {
    tiny: true
  }
}

declare module '@mui/material/TableCell' {
  interface TableCellPropsSizeOverrides {
    tiny: true
  }
}

declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsSizeOverrides {
    tiny: true
  }
}

declare module '@mui/material/ToggleButtonGroup' {
  interface ToggleButtonGroupPropsSizeOverrides {
    tiny: true
  }
}

const themeOptions: ThemeOptions = {
  components: {
    MuiTable: {
      variants: [{ props: { size: 'tiny' }, style: { fontSize: '0.75rem' } }]
    },
    MuiTableCell: {
      variants: [{ props: { size: 'tiny' }, style: { fontSize: '0.75rem', padding: '3px' } }]
    },
    MuiButton: {
      variants: [
        {
          props: { size: 'tiny' },
          style: { fontSize: '0.75rem', padding: '3px', minWidth: '48px' }
        }
      ]
    },
    MuiToggleButton: {
      variants: [{ props: { size: 'tiny' }, style: { fontSize: '0.75rem', padding: '3px' } }]
    }
  },
  numSpacing: value => {
    const baseSpacing = 8

    if (value) {
      return baseSpacing * value
    } else {
      return baseSpacing
    }
  },
  palette: {
    primary: {
      light: '#ffe2c2',
      main: '#ffa53e',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#515154',
      contrastText: '#ffffff'
    },
    background: {
      default: '#e6e7e8'
    },
    error: {
      main: '#f5222d'
    },
    success: {
      main: '#52c41a'
    },
    text: {
      primary: 'rgba(0,0,0,0.65)'
    },
    variety: [
      '#806a52',
      '#e66137',
      '#19b336',
      '#525280',
      '#72e9aa',
      '#3793e6',
      '#e9ac72',
      '#b34d19'
    ]
  },
  typography: {
    fontFamily: 'Montserrat'
  }
}

export const theme = createTheme(themeOptions)
export const colliersTheme = createTheme({
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
    primary: { main: '#003399', contrastText: '#ffffff' },
    secondary: {
      main: '#737378',
      contrastText: '#ffffff'
    },
    variety: [
      '#806a52',
      '#e66137',
      '#19b336',
      '#525280',
      '#72e9aa',
      '#3793e6',
      '#e9ac72',
      '#b34d19'
    ]
  }
})

export const chartTheme: NivoTheme = {
  ...theme.typography,
  textColor: theme.palette.text.primary,
  tooltip: {
    basic: {
      ...theme.typography.body1
    }
  }
}

const { dark, main } = theme.palette.primary

export const chartPrimaryGradientHorizontal = linearGradientDef(
  'primaryGradientHorizontal',
  [
    { offset: 0, color: main },
    { offset: 100, color: dark, opacity: 0 }
  ],
  { gradientTransform: 'rotate(90 0.5 0.5)' }
)

export const chartPrimaryGradientVertical = linearGradientDef('primaryGradientVertical', [
  { offset: 0, color: main },
  { offset: 100, color: dark, opacity: 0 }
])
