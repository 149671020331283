import { createContext, useContext } from 'react'
import { TutorialMessage } from '../tutorial-types'

export type TutorialContextValue = {
  setMessage: (message: TutorialMessage | null) => void
  teaching: boolean
  toggleTeaching: () => void
}

export const TutorialContext = createContext<TutorialContextValue | undefined>(undefined)

export const useTutorial = () => {
  const context = useContext(TutorialContext)

  if (context === undefined) {
    throw new Error('useTutorial must be used within an TutorialProvider')
  }

  return context
}
