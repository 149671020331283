export const AFTER_DT_RANGE_QUERY_KEY = 'after'
export const BEFORE_DT_RANGE_QUERY_KEY = 'before'
export const COUNTRY_QUERY_KEY = 'country'
export const CATCHMENT_DAY_SCOPE_KEY = 'dayScope'
export const CATCHMENT_EXCLUDE_QUERY_KEY = 'exclude'
export const CATCHMENT_PERCENTILE_QUERY_KEY = 'percentile'
export const CATCHMENT_ROLE_QUERY_KEY = 'role'
export const CATCHMENT_TIME_RANGE_KEY = 'timeRange'
export const DT_RANGE_QUERY_KEY = 'dtrange'
export const GEO_QUERY_KEY = 'geo'
export const LOCATION_LOCAL_MARKET_QUERY_KEY = 'location'
export const MAP_OFFSET_X_QUERY_KEY = 'offsetX'
export const MAP_OFFSET_Y_QUERY_KEY = 'offsetY'
export const MAP_ZOOM_QUERY_KEY = 'zoom'
export const SEARCH_QUERY_KEY = 'query'
export const SELECTED_QUERY_KEY = 'selected'
