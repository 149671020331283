// types
import { FC } from 'react'

// ui
import { Box, Button, styled } from '@mui/material'

const StyledButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(20)
}))

type NotificationLinkButtonProps = { link: string | null; onClick?: () => void }

const NotificationLinkButton: FC<NotificationLinkButtonProps> = ({ link, onClick }) => {
  if (!link) return null

  return (
    <Box display='flex' justifyContent='center'>
      <StyledButton
        onClick={() => {
          onClick && onClick()
          window.open(link, '_blank', 'rel=noopener noreferrer')
        }}
        variant='contained'
      >
        Take Me There
      </StyledButton>
    </Box>
  )
}

export default NotificationLinkButton
