import { FC, ReactNode } from 'react'

// graphql
import { ColliersDocument, ColliersQuery } from '../../graphql/generated'

// hooks
import { useQuery } from '@apollo/client'

// ui
import { colliersTheme, theme } from '../../theme'
import ThemeProvider from '@mui/material/styles/ThemeProvider'

interface IProps {
  children: ReactNode
}

const ThemeSwitcher: FC<IProps> = ({ children }) => {
  const { data } = useQuery<ColliersQuery>(ColliersDocument)

  return (
    <ThemeProvider theme={data?.organization.colliers ? colliersTheme : theme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeSwitcher
