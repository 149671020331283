// contexts
import { NotificationContext } from '../notification-context'

// hooks
import useNotificationProvider from './use-notification-provider'

// types
import { FC, ReactNode } from 'react'

type NotificationProviderProps = {
  children: ReactNode
}

const NotificationProvider: FC<NotificationProviderProps> = props => {
  const { loading, notifications } = useNotificationProvider()

  return (
    <NotificationContext.Provider value={{ loading, notifications }}>
      {props.children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
