// components
import BoldTypography from '../../common/BoldTypography'
import ButtonLink from '../../common/ButtonLink'
import VHAligner from '../../common/VHAligner'

// hooks
import useAccountPopover from './useAccountPopover'

// types
import { FC } from 'react'

// ui
import { CircularProgress, Grid, Popover, styled, Typography } from '@mui/material'

const GridContainer = styled(Grid)`
  padding: 2vh 2vw;
  width: 30vw;
`
GridContainer.defaultProps = { container: true }

const OrgCircle = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '100%',
  color: theme.palette.primary.contrastText,
  display: 'flex',
  height: theme.spacing(6),
  justifyContent: 'center',
  width: theme.spacing(6)
}))
OrgCircle.defaultProps = { variant: 'h5' }

type AccountPopoverProps = {
  anchorEl: Element | null
  onClose: () => void
}

const AccountPopover: FC<AccountPopoverProps> = ({ anchorEl, onClose }) => {
  const { accountDetails } = useAccountPopover()

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'center'
      }}
      disableRestoreFocus
      onClose={onClose}
      open={!!anchorEl}
    >
      <GridContainer onMouseLeave={onClose} spacing={1}>
        {accountDetails ? (
          <>
            <Grid item xs={3}>
              <OrgCircle>{accountDetails.orgName.at(0)}</OrgCircle>
            </Grid>
            <Grid item xs={9}>
              <BoldTypography>{accountDetails.email}</BoldTypography>
              <Typography variant='body2'>{accountDetails.orgName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2'>{`Your account expires on ${accountDetails.expiryDate.toFormat(
                'd MMMM yyyy'
              )}`}</Typography>
              <ButtonLink onClick={onClose} text='Reset Password' to='/reset-password' />
            </Grid>
            {!!accountDetails.tier && (
              <Grid item xs={12}>
                <Typography>Account Tier</Typography>
                <BoldTypography variant='body2'>{accountDetails.tier}</BoldTypography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography>Follows</Typography>
              <BoldTypography variant='body2'>{`Following ${
                accountDetails.followStats.current
              } assets (${
                accountDetails.followStats.total - accountDetails.followStats.current
              } unused)`}</BoldTypography>
              <Typography>Demographic Follows</Typography>
              <BoldTypography variant='body2'>{`${
                accountDetails.followStats.currentDemographic
              } with demographics (${
                accountDetails.followStats.totalDemographic -
                accountDetails.followStats.currentDemographic
              } unused)`}</BoldTypography>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <VHAligner>
              <CircularProgress />
            </VHAligner>
          </Grid>
        )}
      </GridContainer>
    </Popover>
  )
}

export default AccountPopover
