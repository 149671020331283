import { FC } from 'react'

// ui
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

const Content = styled(Paper)(({ theme }) => ({
  left: '50vh',
  padding: theme.spacing(2),
  position: 'absolute',
  right: '50vh',
  top: '30vh'
}))

interface IProps {
  feature: string
  onClose: () => void
  open: boolean
}

const UpgradeModal: FC<IProps> = ({ feature, onClose, open }) => {
  return (
    <Modal onClose={onClose} open={open}>
      <Content>
        <Typography variant='h6'>Restricted Functionality</Typography>
        <Typography variant='body2'>{`${feature} is not available within your company’s current license agreement. Please contact your terain
          representative for more information.`}</Typography>
      </Content>
    </Modal>
  )
}

export default UpgradeModal
