import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

interface IProps {
  inline?: boolean
}

const BoldTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'inline'
})<IProps>(({ inline }) => ({
  display: inline ? 'inline' : 'block',
  fontWeight: 'bold'
}))

export default BoldTypography
