// contexts
import { TutorialContext } from './tutorial-context'

// hooks
import useTutorialProvider from './use-tutorial-provider'

// types
import { FC, ReactNode } from 'react'

// ui
import { Popover, styled, Typography } from '@mui/material'

const TutorialText = styled(Typography)(({ theme }) => ({
  maxWidth: theme.spacing(60),
  padding: theme.spacing(2),
  whiteSpace: 'pre-line'
}))

type TutorialProviderProps = {
  children: ReactNode
}

const TutorialProvider: FC<TutorialProviderProps> = ({ children }) => {
  const { message, setMessage, teaching, toggleTeaching } = useTutorialProvider()

  return (
    <>
      <TutorialContext.Provider value={{ setMessage, teaching, toggleTeaching }}>
        {children}
      </TutorialContext.Provider>

      <Popover
        anchorEl={message?.anchor}
        open={!!message}
        onClose={() => setMessage(null)}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        <TutorialText>{message?.text}</TutorialText>
      </Popover>
    </>
  )
}

export default TutorialProvider
