import { FC } from 'react'

// components
import VHAligner from '../common/VHAligner'

// hooks
import { useAuth } from '../../contexts/auth-context'
import { usePermissions } from '../../contexts/permissions/permissions-context'

// router
import { Link, useLocation } from 'react-router-dom'

// ui
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

const Container = styled(VHAligner)`
  cursor: pointer;
`

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

const StyledLock = styled(LockOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}))
StyledLock.defaultProps = { fontSize: 'small' }

interface IPageOptionProps {
  selected: boolean
}

const PageOption = styled('div')<IPageOptionProps>(({ selected, theme }) => {
  const { primary, text } = theme.palette

  return {
    backgroundColor: selected ? primary.contrastText : undefined,
    borderRadius: '0.25rem',
    color: selected ? text.primary : primary.contrastText,
    padding: '2px 5px'
  }
})

interface IProps {
  locked: boolean
  path: string
  title: string
}

const LockableLink: FC<IProps> = ({ locked, path, title }) => {
  const { token } = useAuth()
  const { pathname } = useLocation()
  const { upgrade } = usePermissions()

  const disabled = locked || !token

  return (
    <Container onClick={() => disabled && upgrade(title)}>
      <Typography component='div'>
        <PageOption selected={!disabled && pathname === path}>
          {!disabled ? (
            <StyledLink title={title} to={path}>
              {title}
            </StyledLink>
          ) : (
            title
          )}
        </PageOption>
      </Typography>
      {disabled && <StyledLock />}
    </Container>
  )
}

export default LockableLink
