// types
import { Notification } from './notification-types'
import { NotificationFragment } from '../../graphql/generated'

// utils
import { DateTime } from 'luxon'

export const transformNotificationFragment = (fragment: NotificationFragment): Notification => ({
  id: parseInt(fragment.id),
  author: `${fragment.author.firstName} ${fragment.author.lastName}`,
  content: fragment.content,
  createdAt: DateTime.fromISO(fragment.createdAt),
  link: fragment.link || null,
  previewText: fragment.previewText || null,
  seenByCurrentUser: fragment.seenByCurrentUser,
  title: fragment.title
})
