import { useCallback, useEffect, useMemo, useState } from 'react'

// constants
import { DT_RANGE_QUERY_KEY } from '../constants/route-constants'

// router
import { useMatch, useSearchParams } from 'react-router-dom'

// types
import { AssetEnum } from '../graphql/generated'
import { AssetKey } from '../types/asset-types'

// utils
import { DateTime } from 'luxon'

const getAssetEnum = (from: string) => {
  switch (from) {
    case 'analysis-area':
      return AssetEnum.AnalysisAreaGeoJsonType
    case 'brand':
      return AssetEnum.BrandType
    case 'cluster':
      return AssetEnum.ClusterGeoJsonType
    case 'location':
      return AssetEnum.LocationGeoJsonType
    case 'segment':
      return AssetEnum.SegmentGeoJsonType
    default:
      return undefined
  }
}

export const useMatchedAssetKey = (): AssetKey | null => {
  const match = useMatch('/:assetType/:assetId/*')

  return useMemo(() => {
    let assetId: number | undefined
    let assetType: AssetEnum | undefined

    if (match) {
      const { assetId: matchedAssetId, assetType: matchedAssetType } = match.params
      assetId = matchedAssetId ? parseInt(matchedAssetId) : undefined
      assetType = matchedAssetType ? getAssetEnum(matchedAssetType) : undefined
    }

    if (!!assetId && !!assetType) {
      return { id: assetId, type: assetType }
    }

    return null
  }, [match])
}

export const useSearchParam = (
  key: string,
  initialParam?: string
): [string | null, (newVal: string | null) => void] => {
  const [initialHasBeenSet, setInitialHasBeenSet] = useState<boolean>(!initialParam)
  const [searchParams, setSearchParams] = useSearchParams()
  const value = searchParams.get(key)

  const setValue = useCallback(
    (newVal: string | null) => {
      if (newVal) {
        setSearchParams(params => {
          if (params.get(key)) {
            params.set(key, newVal)
            return params
          }

          return [...params.entries(), [key, newVal]]
        })
      } else if (searchParams.get(key)) {
        setSearchParams(params => {
          params.delete(key)
          return params
        })
      }
    },
    [key, searchParams, setSearchParams]
  )

  useEffect(() => {
    if (!initialHasBeenSet && value === initialParam) {
      setInitialHasBeenSet(true)
    }

    if (!value && !!initialParam && !initialHasBeenSet) {
      setValue(initialParam)
    }
  }, [initialHasBeenSet, initialParam, setValue, value])

  return [value, setValue]
}

const DT_RANGE_PARAM_FORMAT = 'yyyy-MM-dd'

const dtRangeParamFrom = (dateRange: [DateTime, DateTime]) => {
  return `${dateRange[0].toFormat(DT_RANGE_PARAM_FORMAT)}|${dateRange[1].toFormat(
    DT_RANGE_PARAM_FORMAT
  )}`
}

export const useDtRangeSearchParam = (
  initialDtRange?: [DateTime, DateTime],
  key?: string
): [[DateTime, DateTime] | null, (newVal: [DateTime, DateTime] | null) => void] => {
  let initialParam: string | undefined = undefined

  if (initialDtRange) {
    initialParam = dtRangeParamFrom(initialDtRange)
  }

  const [dtRangeParam, setDtRangeParam] = useSearchParam(key || DT_RANGE_QUERY_KEY, initialParam)

  const dtRange = useMemo(() => {
    let newDtRange: [DateTime, DateTime] | null = null

    if (dtRangeParam) {
      const split = dtRangeParam.split('|')
      newDtRange = split.map(p => DateTime.fromFormat(p, DT_RANGE_PARAM_FORMAT)) as [
        DateTime,
        DateTime
      ]
    }

    return newDtRange
  }, [dtRangeParam])

  const setDtRange = useCallback(
    (newVal: [DateTime, DateTime] | null) => {
      if (newVal) {
        setDtRangeParam(dtRangeParamFrom(newVal))
      } else {
        setDtRangeParam(null)
      }
    },
    [setDtRangeParam]
  )

  return [dtRange, setDtRange]
}
