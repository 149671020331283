import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'

const AUTH_TOKEN = 'AUTH_TOKEN'

interface IAuthContext {
  login: ((email: string, password: string) => Promise<boolean>) | null
  logout: (() => void) | null
  token: string | null
}

interface IProps {
  checkForToken: boolean
  children: ReactNode
}

const AuthContext = createContext<IAuthContext>({ login: null, logout: null, token: null })

const AuthProvider: FC<IProps> = ({ checkForToken, children }) => {
  const [checkingToken, setCheckingToken] = useState<boolean>(checkForToken)
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const storageToken = localStorage.getItem(AUTH_TOKEN)

    if (storageToken) {
      setToken(storageToken)
    }

    setCheckingToken(false)
  }, [])

  const login = async (email: string, password: string) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/users/obtain-auth-token/`

    try {
      const result = await fetch(url, {
        body: JSON.stringify({ username: email, password }),
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        method: 'POST'
      })

      const { token } = await result.json()

      if (token) {
        localStorage.setItem(AUTH_TOKEN, token)
        setToken(token)
        return true
      }

      return false
    } catch (e) {
      return false
    }
  }

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN)
    setToken(null)
  }

  return checkingToken ? null : (
    <AuthContext.Provider value={{ login, logout, token }}>{children}</AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
