// types
import { FC } from 'react'
import { Notification } from './notification-types'

// ui
import { Stack, styled, Typography } from '@mui/material'

const NotificationBody = styled('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: theme.typography.fontFamily,
  justifyContent: 'center'
}))

type NotificationContentProps = Pick<Notification, 'author' | 'content' | 'createdAt' | 'title'>

const NotificationContent: FC<NotificationContentProps> = props => (
  <Stack spacing={1}>
    <Typography textAlign='center' variant='h5'>
      {props.title}
    </Typography>

    <Typography textAlign='center' variant='body2'>{`Posted on ${props.createdAt.toFormat(
      'dd/MM/yyyy'
    )} at ${props.createdAt.toFormat('hh:mma')} by ${props.author}`}</Typography>

    <NotificationBody dangerouslySetInnerHTML={{ __html: props.content }} />
  </Stack>
)

export default NotificationContent
