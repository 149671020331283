import { ApolloClient, InMemoryCache, InMemoryCacheConfig, Reference } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

export const apolloCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    BrandType: {
      fields: {
        follow(existingFollow: Reference, { canRead }) {
          // Filter out any dangling references left over from unfollowing
          return !!existingFollow && canRead(existingFollow) ? existingFollow : null
        }
      }
    },
    CensusType: { merge: true },
    ClientType: { merge: true },
    ClientUserStatType: { keyFields: ['user', ['id']] },
    ClusterType: {
      fields: {
        follow(existingFollow: Reference, { canRead }) {
          // Filter out any dangling references left over from unfollowing
          return !!existingFollow && canRead(existingFollow) ? existingFollow : null
        }
      }
    },
    ComputedCensusType: { merge: true },
    DataConnections: { keyFields: [] },
    DemographicsChartType: { keyFields: ['chartId', 'demographicId'] },
    LocationType: {
      fields: {
        follow(existingFollow: Reference, { canRead }) {
          // Filter out any dangling references left over from unfollowing
          return !!existingFollow && canRead(existingFollow) ? existingFollow : null
        }
      }
    },
    OrganizationType: { merge: true },
    OrgFollowType: {
      fields: {
        demographicFollow(existingDmgFollow: Reference, { canRead }) {
          // Filter out any dangling references left over from removing demographics
          return !!existingDmgFollow && canRead(existingDmgFollow) ? existingDmgFollow : null
        }
      },
      merge: true
    },
    FollowGroupType: { merge: true },
    FollowStatsType: { merge: true },
    GeoDistanceType: { keyFields: ['geo', 'id'] },
    GooglePlaceType: { keyFields: ['address', 'name'] },
    NotificationType: { merge: true },
    QmCatchmentBenchmark: { keyFields: ['benchmark', 'dmgid'], merge: true },
    QnAccessible: { merge: true },
    QnAffinity: { merge: true },
    QnAsset: { merge: true },
    QnAvailable: { merge: true },
    QnCatchment: { merge: true },
    QnCluster: { merge: true },
    QnDailyprofile: { merge: true },
    QnDwell: { merge: true },
    QnDistance: { merge: true },
    QnMarket: { merge: true },
    QnMedian: { merge: true },
    QnMetrics: { merge: true },
    QnRepeat: { merge: true },
    QnVenue: { merge: true },
    QnVisits: { merge: true },
    QnVisitsperdevice: { merge: true },
    QnWeeklyprofile: { merge: true },
    QnYearonyear: { merge: true },
    Query: {
      fields: {
        analysisArea: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'AnalysisAreaGeoJsonType',
              id: !!args && args.id
            })
          }
        },
        brand: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'BrandType',
              id: !!args && args.id
            })
          }
        },
        cluster: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'ClusterGeoJSONType',
              id: !!args && args.id
            })
          }
        },
        location: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'LocationGeoJSONType',
              id: !!args && args.id
            })
          }
        },
        segment: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'SegmentGeoJSONType',
              id: !!args && args.id
            })
          }
        }
      }
    },
    SegmentType: {
      fields: {
        follow(existingFollow: Reference, { canRead }) {
          // Filter out any dangling references left over from unfollowing
          return !!existingFollow && canRead(existingFollow) ? existingFollow : null
        }
      }
    },
    UserType: { merge: true }
  }
}

export const createApolloClient = (logout: (() => void) | null, token: string | null) => {
  const apolloHttpLink = new BatchHttpLink({
    uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
    batchMax: 5,
    batchInterval: 20
  })

  const apolloAuthLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Token ${token}` : '',
        'terain-url': window.location.href
      }
    }
  })

  const errorLink = onError(({ networkError }) => {
    // If we get a 401, logout to remove expired token and force new login
    if (networkError?.message === 'Response not successful: Received status code 401' && !!logout) {
      logout()
    }
  })

  const apolloClient = new ApolloClient({
    link: apolloAuthLink.concat(errorLink).concat(apolloHttpLink),
    cache: new InMemoryCache(apolloCacheConfig),
    connectToDevTools: true
  })

  return apolloClient
}
