import { ApolloCache } from '@apollo/client'
import { AssetEnum } from '../graphql/generated'

interface IFollow {
  id: string
  __typename?: 'OrgFollowType' | undefined
}

export const updateCacheAssetFollow = <T extends IFollow>(
  assetId: number,
  assetType: AssetEnum,
  cache: ApolloCache<any>,
  follow: T
) => {
  // clusters, locations and segments have follow as a child
  // of properties and not their geoJSON types, so find them in order
  // to update correct cache object
  let identityType: string

  switch (assetType) {
    case AssetEnum.ClusterGeoJsonType:
      identityType = 'ClusterType'
      break
    case AssetEnum.LocationGeoJsonType:
      identityType = 'LocationType'
      break
    case AssetEnum.SegmentGeoJsonType:
      identityType = 'SegmentType'
      break
    default:
      identityType = assetType
  }

  cache.modify({
    id: cache.identify({ id: assetId, __typename: identityType }),
    fields: {
      follow(currentFollow) {
        const newCacheId = cache.identify({ id: follow.id, __typename: 'OrgFollowType' })

        // we need to check if the new follow is the same as the old, if it is we simply update it,
        // otherwise we replace it. This has to be done since dangling references are not deleted,
        // so we need to ignore them until they are replaced. In addition, we add a __ref property
        // to newly added follows so that future cache updates here are consistent.

        if (currentFollow?.__ref === newCacheId) {
          return { ...currentFollow, ...follow }
        }

        return { ...follow, __ref: newCacheId }
      }
    }
  })
}
