import { FC } from 'react'

// components
import VHAligner from '../../components/common/VHAligner'

// constants
import { NO_DMG_FOLLOWS_LEFT } from '../../constants/message-constants'

// graphql
import { AssetEnum, FollowStatsDocument, FollowStatsQuery } from '../../graphql/generated'

// hooks
import { useQuery } from '@apollo/client'
import { useUpdateDmgFollowMutation } from '../../hooks/common-queries'

// types
import { UpgradeFollowModalProps } from './types'

// ui
import { Button, Modal, Paper, Stack, styled, Typography } from '@mui/material'

const Content = styled(Paper)(({ theme }) => ({
  left: '54vh',
  padding: theme.spacing(2),
  position: 'absolute',
  right: '54vh',
  top: '30vh'
}))

const StyledVHAligner = styled(VHAligner)(({ theme }) => ({
  paddingTop: theme.spacing()
}))

const UpgradeFollowModal: FC<UpgradeFollowModalProps> = ({
  assetId,
  assetType,
  followId,
  onClose
}) => {
  const { data: followStatsData } = useQuery<FollowStatsQuery>(FollowStatsDocument, {
    fetchPolicy: 'cache-only'
  })

  const [updateDmgFollow] = useUpdateDmgFollowMutation(assetId, assetType)

  if (!followStatsData) return null

  const handleUpdate = () => {
    updateDmgFollow({ variables: { followId, isDemographic: true } })
    onClose()
  }

  const { currentDemographic, totalDemographic } = followStatsData.organization.followStats
  const canUpgrade = totalDemographic > currentDemographic

  return (
    <Modal onClose={onClose} open={true}>
      <Content>
        <Stack spacing={1}>
          <Typography variant='h6'>Upgrade Follow</Typography>
          <Typography variant='body2'>
            To view demographic metrics, this asset must be upgraded to a demographic follow.
          </Typography>
          {!canUpgrade && <Typography variant='body2'>{NO_DMG_FOLLOWS_LEFT}</Typography>}
        </Stack>
        {canUpgrade && (
          <StyledVHAligner>
            <Button onClick={handleUpdate} variant='contained'>
              Upgrade Follow
            </Button>
          </StyledVHAligner>
        )}
      </Content>
    </Modal>
  )
}

export default UpgradeFollowModal
