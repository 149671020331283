// hooks
import { useEffect, useMemo } from 'react'
import { useFragment, useLazyQuery } from '@apollo/client'
import { useMatchedAssetKey } from './route-hooks'
import { useMutation } from '@apollo/client'

// types
import {
  AssetEnum,
  AssetGeometryDocument,
  AssetGeometryQuery,
  AssetGeometryQueryVariables,
  FollowStatsDocument,
  FullFollowStatsFragmentDoc,
  UpdateDemographicFollowDocument,
  UpdateDemographicFollowMutation,
  UpdateDemographicFollowMutationVariables
} from '../graphql/generated'
import { FeatureCollection } from 'geojson'

// utils
import { updateCacheAssetFollow } from '../utils/apollo-utils'

export const useAssetGeoData = () => {
  const assetKey = useMatchedAssetKey()

  const [loadAssetGeometry, { data, loading }] = useLazyQuery<
    AssetGeometryQuery,
    AssetGeometryQueryVariables
  >(AssetGeometryDocument)

  useEffect(() => {
    if (assetKey) {
      const { id, type } = assetKey
      loadAssetGeometry({ variables: { assetId: id, assetType: type } })
    }
  }, [assetKey, loadAssetGeometry])

  const geoData = useMemo<FeatureCollection | null>(() => {
    if (!data?.map.geometry) return null

    return {
      features: [
        {
          geometry: data.map.geometry,
          properties: null,
          type: 'Feature'
        }
      ],
      type: 'FeatureCollection'
    }
  }, [data])

  return { data: geoData, loading }
}

export const useFollowStatsFragment = (organizationId: number) =>
  useFragment({
    fragment: FullFollowStatsFragmentDoc,
    fragmentName: 'FullFollowStats',
    from: { __typename: 'OrganizationType', id: organizationId }
  })

export const useUpdateDmgFollowMutation = (
  assetId: number,
  assetType: AssetEnum,
  currentDmgFollowId?: number
) =>
  useMutation<UpdateDemographicFollowMutation, UpdateDemographicFollowMutationVariables>(
    UpdateDemographicFollowDocument,
    {
      refetchQueries: [{ query: FollowStatsDocument }],
      update(cache, { data }) {
        if (data?.updateDemographicFollow?.follow) {
          const { follow } = data.updateDemographicFollow

          if (!!currentDmgFollowId && !follow.demographicFollow) {
            cache.evict({
              id: cache.identify({ id: currentDmgFollowId, __typename: 'DemographicFollowType' })
            })

            cache.gc()
          } else {
            updateCacheAssetFollow(assetId, assetType, cache, follow)
          }
        }
      }
    }
  )
