import { DateTime } from 'luxon'

export const dateSort = (a: DateTime, b: DateTime) => (a > b ? 1 : a < b ? -1 : 0)

export const getExpiryDate = (orgExpiryDate: any, profileExpiryDate: any | null | undefined) => {
  const orgExpiry = DateTime.fromISO(orgExpiryDate)
  const profileExpiry = profileExpiryDate ? DateTime.fromISO(profileExpiryDate) : null
  return profileExpiry && orgExpiry > profileExpiry ? profileExpiry : orgExpiry
}

export const getDataEndDate = (now: DateTime = DateTime.now()) => {
  return now.day < 7
    ? now.minus({ month: 2 }).endOf('month')
    : now.minus({ month: 1 }).endOf('month')
}

export const getLastNMonthsRange = (n: number): [DateTime, DateTime] => {
  const endDate = DateTime.now().minus({ month: 1 }).endOf('month')
  const startDate = endDate.minus({ month: n - 1 }).startOf('month')
  return [startDate, endDate]
}
