import { FC } from 'react'

// components
import VHAligner from '../common/VHAligner'

// hooks
import { useQuery } from '@apollo/client'

// types
import { ConnectionsDocument, ConnectionsQuery } from '../../graphql/generated'

// ui
import { CircularProgress, Modal, Paper, Stack, styled, Typography } from '@mui/material'

const Content = styled(Paper)(({ theme }) => ({
  left: '60vh',
  padding: theme.spacing(),
  position: 'absolute',
  right: '60vh',
  top: '30vh'
}))

interface IProps {
  onClose: () => void
  open: boolean
}

const ConnectionsModal: FC<IProps> = ({ onClose, open }) => {
  const { data } = useQuery<ConnectionsQuery>(ConnectionsDocument)

  return (
    <Modal onClose={onClose} open={open}>
      <Content>
        {data?.connections ? (
          <Stack direction='column' spacing={1}>
            <Typography variant='body2'>{`Django Admin DB: ${data.connections.adminDb}`}</Typography>
            <Typography variant='body2'>{`Macro DB: ${data.connections.macroDb}`}</Typography>
            <Typography variant='body2'>{`KDB: ${data.connections.kdb}`}</Typography>
          </Stack>
        ) : (
          <VHAligner>
            <CircularProgress />
          </VHAligner>
        )}
      </Content>
    </Modal>
  )
}

export default ConnectionsModal
