import { FC } from 'react'

// ui
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Popover from '@mui/material/Popover'
import styled from '@mui/material/styles/styled'
import Typography from '@mui/material/Typography'

const GridContainer = styled(Grid)`
  padding: 2vh 2vw;
  width: 42vw;
`
GridContainer.defaultProps = { container: true }

interface IProps {
  anchorEl: Element | null
  onClose: () => void
}

const InfoPopover: FC<IProps> = ({ anchorEl, onClose }) => {
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'center'
      }}
      disableRestoreFocus
      onClose={onClose}
      open={!!anchorEl}
    >
      <GridContainer onMouseLeave={onClose} spacing={1}>
        <Grid item xs={12}>
          <Typography>
            Bodlero Limited t/a Fetch Analytics &copy; 2015-
            {new Date().getFullYear()}. All rights reserved.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <Link href='https://fetchanalytics.ai/terms-of-use' underline='hover'>
              Terms of use
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <Link href='https://fetchanalytics.ai/privacy-policy' underline='hover'>
              Privacy policy
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            <Link href='mailto:product@fetchanalytics.ai' underline='hover'>
              Contact the team
            </Link>
          </Typography>
        </Grid>
      </GridContainer>
    </Popover>
  )
}

export default InfoPopover
