// components
import NotificationContent from '../notification-content'
import NotificationLinkButton from '../notification-link-button'

// hooks
import useBlockingNotificationModal from './use-blocking-notification-modal'

// types
import { FC } from 'react'

// ui
import { Button, Modal, Paper, Stack, styled } from '@mui/material'

const Content = styled(Paper)(({ theme }) => ({
  left: '50vh',
  padding: theme.spacing(),
  position: 'absolute',
  right: '50vh',
  top: '20vh'
}))

const BlockingNotificationModal: FC = () => {
  const { handleClose, openedNotification, seeing } = useBlockingNotificationModal()
  if (!openedNotification) return null

  return (
    <Modal onClose={handleClose} open={!!openedNotification}>
      <Content>
        <Stack spacing={2}>
          <NotificationContent
            author={openedNotification.author}
            content={openedNotification.content}
            createdAt={openedNotification.createdAt}
            title={openedNotification.title}
          />

          <Stack alignItems='center' direction='row' justifyContent='center' spacing={2}>
            <NotificationLinkButton link={openedNotification.link} onClick={handleClose} />

            <Button disabled={seeing} onClick={handleClose} variant='outlined'>
              Close
            </Button>
          </Stack>
        </Stack>
      </Content>
    </Modal>
  )
}

export default BlockingNotificationModal
