// components
import { Link } from 'react-router-dom'

// hooks
import { useNotifications } from './notification-context'

// types
import { FC } from 'react'

// ui
import { Badge, IconButton, styled, Tooltip } from '@mui/material'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText
}))

const NotificationButton: FC = () => {
  const { notifications } = useNotifications()
  const unseen = notifications.filter(notification => !notification.seenByCurrentUser)

  return (
    <Tooltip title='Notification Centre'>
      <Link to='/notification'>
        <StyledIconButton>
          <Badge badgeContent={unseen.length || null} color='secondary'>
            {unseen.length ? (
              <NotificationsActiveOutlinedIcon />
            ) : (
              <NotificationsNoneOutlinedIcon />
            )}
          </Badge>
        </StyledIconButton>
      </Link>
    </Tooltip>
  )
}

export default NotificationButton
