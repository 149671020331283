import { Component, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

// ui
import { Modal, Paper, Stack, styled, Typography } from '@mui/material'

const Content = styled(Paper)(({ theme }) => ({
  left: '54vh',
  padding: theme.spacing(2),
  position: 'absolute',
  right: '54vh',
  top: '30vh'
}))

interface IErrorBoundaryProps {
  children: ReactNode
  token: string | null
}

interface IErrorBoundaryState {
  hasError: boolean
  redirect: boolean
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false,
    redirect: false
  }

  public static getDerivedStateFromError(): IErrorBoundaryState {
    return { hasError: true, redirect: false }
  }

  public async componentDidCatch(error: Error) {
    if (!this.props.token) return

    const url = `${process.env.REACT_APP_API_BASE_URL}/ui-log/`

    await fetch(url, {
      body: JSON.stringify({
        location: window.location.href,
        message: error.message,
        severity: 'error',
        stack: error.stack
      }),
      headers: {
        authorization: `Token ${this.props.token}`,
        'content-type': 'application/json;charset=UTF-8'
      },
      method: 'POST'
    })
  }

  public render() {
    return (
      <>
        {this.state.hasError ? <Navigate to='/' replace={true} /> : this.props.children}
        <Modal onClose={() => this.setState({ hasError: false })} open={this.state.hasError}>
          <Content>
            <Stack spacing={1}>
              <Typography variant='h6'>An Error Occurred</Typography>
              <Typography variant='body2'>
                The error has been logged and you will be redirected home
              </Typography>
            </Stack>
          </Content>
        </Modal>
      </>
    )
  }
}

export default ErrorBoundary
