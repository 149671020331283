import { FC, lazy, Suspense, useEffect } from 'react'

// hooks
import { useAuth } from '../contexts/auth-context'
import { usePermissions } from '../contexts/permissions/permissions-context'

// routes
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

const AreaAnalysis = lazy(() => import('./AreaAnalysis/AreaAnalysis'))
const Csm = lazy(() => import('../features/csm/csm'))
const Downloader = lazy(() => import('../features/downloader/downloader'))
const Explore = lazy(() => import('./Explore/Explore'))
const Group = lazy(() => import('./Group/Group'))
const Help = lazy(() => import('./Help'))
const Home = lazy(() => import('./Home/Home'))
const Login = lazy(() => import('./Login/Login'))
const MainMenuOnMapProvider = lazy(() => import('./common/MainMenuOnMap/context'))
const NotificationLayout = lazy(() => import('../features/notification/notification-layout'))
const ResetPassword = lazy(() => import('../features/reset-password/reset-password'))

const MainRoutes: FC = () => {
  const { token } = useAuth()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    loading,
    permissions: { admin, areaAnalysis, csm, downloader, groupView, locationAffinity }
  } = usePermissions()

  useEffect(() => {
    if (!pathname.includes('reset-password') && !token) {
      navigate('/login')
    }
  }, [navigate, pathname, token])

  return (
    <Suspense>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/help' element={<Help />} />
        <Route path='/notification/*' element={<NotificationLayout />} />
        {(admin || areaAnalysis) && <Route path='/analysis-area/*' element={<AreaAnalysis />} />}
        {(admin || downloader) && <Route path='/downloader' element={<Downloader />} />}
        {(admin || groupView) && <Route path='/group' element={<Group />} />}
        {(admin || csm) && <Route path='/csm' element={<Csm />} />}

        {(admin || locationAffinity) && (
          <Route
            path='/location-affinity/*'
            element={
              <MainMenuOnMapProvider>
                <Explore mode='location-affinity' />
              </MainMenuOnMapProvider>
            }
          />
        )}

        {!token && <Route path='login' element={<Login />} />}
        <Route path='reset-password/*' element={<ResetPassword />} />
        {/* empty catch all for when permissions are still loading */}
        <Route
          path='*'
          element={
            loading ? (
              <div></div>
            ) : (
              <MainMenuOnMapProvider>
                <Explore mode='search' />
              </MainMenuOnMapProvider>
            )
          }
        />
      </Routes>
    </Suspense>
  )
}

export default MainRoutes
